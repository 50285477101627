@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,500,700);

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../fonts/icomoon.eot');
    src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.woff') format('woff'),
        url('../fonts/icomoon.ttf') format('truetype');
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    font-weight: 300;
}

a {
  text-decoration: none;
  color: none;
  border: 0;
}

h1 {
  color: #ffffff;
}

p {
  color: #ffffff;
}

button:focus {
    outline: 0;
}

body{
    background: #2193c7;
	background: -webkit-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: -moz-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: -o-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: linear-gradient(to bottom right, #2193c7 0%, #146b98 100%);
    
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 300;
	color: white;

	::-webkit-input-placeholder { /* WebKit browsers */
		font-family: 'Source Sans Pro', sans-serif;
			color:    white;
		font-weight: 300;
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		font-family: 'Source Sans Pro', sans-serif;
		 color:    white;
		 opacity:  1;
		font-weight: 300;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
		font-family: 'Source Sans Pro', sans-serif;
		 color:    white;
		 opacity:  1;
		font-weight: 300;
	}
	:-ms-input-placeholder { /* Internet Explorer 10+ */
		font-family: 'Source Sans Pro', sans-serif;
		 color:    white;
		font-weight: 300;
	}
}

.small {
    font-size: 15px;

    &.small--psuedonym {
        position: relative;
        top: -5px;
        color: #a7acaf;
    }
}

.content {
	background: #2193c7;
	background: -webkit-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: -moz-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: -o-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: linear-gradient(to bottom right, #2193c7 0%, #146b98 100%);

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100%;
    padding-bottom: 60px;
}

.wrapper{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&.form-success{
		.container{
			h1{
				transform: translateY(85px);
			}

			h2{
				height: 0;
				opacity: 0;
			}

			h2.hidden{
				height: auto;
				opacity: 1;
			}
		}

		.bg-bubbles{

			li{
				opacity: 0;

				transition: opacity 1s linear;
			}
		}
	}
}

.loginNotification {
    position: absolute;
    bottom: 150px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    min-width: 200px;
    border: 2px solid #ffffff;
    background: rgba(255,255,255,0.3);
    text-align: center;
    opacity: 0;
    z-index: 50;
    transition: opacity .5s linear;
}

.loginNotification p {
    color: #ffffff;
}

.loginNotification.success {
    opacity: 1;
    border: 2px solid #66CD00;
    background: #66CD00;
}

.loginNotification.fail {
    opacity: 1;
    border: 2px solid #CD2626;
    background: #CD2626;
}

.container{
	position: relative;
	top: 50%;
	max-width: 600px;
	margin: 0 auto;
	margin-top: -200px;
	padding: 80px 0;
	height: 400px;
	text-align: center;

	h1{
		font-size: 50px;
		transition-duration: 1s;
		transition-timing-function: ease-in-put;
		font-weight: 200;
	}

	h2{
		height: auto;
		font-size: 25px;
		transition-duration: 1s;
		transition-timing-function: ease-in-put;
		font-weight: 200;
		opacity: 1;
	}

	h2.hidden{
		height: 0;
		opacity: 0;
	}
}

.company-logo {
    position: absolute;
    width: auto;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    
    img {
        width: 250px;
        height: auto;
    }
    
    p {
        padding-top: 10px;
    }
}

form{
	padding: 10px 0;
	position: relative;
	z-index: 2;

	.captcha {
		width: 300px;
		margin: 0 auto 10px;
	}

	input{
        display: block;
		appearance: none;
		outline: 0;
		border: 1px solid fade(white, 40%);
		background-color: fade(white, 20%);
		width: 250px;

		border-radius: 3px;
		padding: 10px 15px;
		margin: 0 auto 10px auto;
		display: block;
		text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
		font-size: 18px;

		color: white;

		transition-duration: 0.25s;
		font-weight: 300;

		&:hover{
			background-color: fade(white, 40%);
		}

		&:focus{
			background-color: white;
			width: 300px;

			color: #2193c7;
		}
	}

	button{
		appearance: none;
		outline: 0;
		background-color: white;
		border: 0;
		padding: 10px 15px;
		color: #2193c7;
		border-radius: 3px;
		width: 250px;
		cursor: pointer;
		font-size: 18px;
		transition-duration: 0.25s;

		&:hover{
			background-color: rgb(245, 247, 249);
		}
	}
}

.bg-bubbles{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: 1;

	li{
		position: absolute;
		list-style: none;
		display: block;
		width: 40px;
		height: 40px;
		background-color: fade(white, 15%);
		bottom: -160px;

		opacity: 1;

		-webkit-animation: square 25s infinite;
		animation:         square 25s infinite;

		-webkit-transition-timing-function: linear;
		transition-timing-function: linear;

		&:nth-child(1){
			left: 10%;
		}

		&:nth-child(2){
			left: 20%;

			width: 80px;
			height: 80px;

			animation-delay: 2s;
			animation-duration: 17s;
		}

		&:nth-child(3){
			left: 25%;
			animation-delay: 4s;
		}

		&:nth-child(4){
			left: 40%;
			width: 60px;
			height: 60px;

			animation-duration: 22s;

			background-color: fade(white, 25%);
		}

		&:nth-child(5){
			left: 70%;
		}

		&:nth-child(6){
			left: 80%;
			width: 120px;
			height: 120px;

			animation-delay: 3s;
			background-color: fade(white, 20%);
		}

		&:nth-child(7){
			left: 32%;
			width: 160px;
			height: 160px;

			animation-delay: 7s;
		}

		&:nth-child(8){
			left: 55%;
			width: 20px;
			height: 20px;

			animation-delay: 15s;
			animation-duration: 40s;
		}

		&:nth-child(9){
			left: 25%;
			width: 10px;
			height: 10px;

			animation-delay: 2s;
			animation-duration: 40s;
			background-color: fade(white, 30%);
		}

		&:nth-child(10){
			left: 90%;
			width: 160px;
			height: 160px;

			animation-delay: 11s;
		}
	}
}

@-webkit-keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-120vh) rotate(600deg); }
}
@keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-120vh) rotate(600deg); }
}

@-webkit-keyframes shrink {
	0%   { transform: scale(1); }
    100% { transform: scale(0); }
}
@keyframes shrink {
  0%   { transform: scale(1); }
  100% { transform: scale(0); }
}


@-webkit-keyframes loading-square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-120vh) rotate(600deg); }
}
@keyframes loading-square {
    0%   { transform: translateY(-50%); }
    20% { transform: translateY(-50%) rotate(180deg); }
    40% { transform: translateY(-100%) rotate(360deg); }
    60% { transform: translateY(0%) rotate(540deg); }
    80% { transform: translateY(-50%) rotate(720deg); }
    100% { transform: translateY(-50%) rotate(900deg); }
}



.loadingWrap {
    position: relative;
    padding: 10px 0;
    
    opacity: 1;
    transition: opacity .5s linear;
    
    z-index: 500;
    
    &.hidden {
        opacity: 0;
    }
    
    .bubble {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        background: #ffffff;
        opacity: 0.15;
        
        -webkit-animation: loading-square 5s infinite;
		animation:         loading-square 5s infinite;
    }
    
    .title {
        padding: 0 0 0 40px;
    }
}

.loadingWrap.none {
    display: none;
}

.loadingWrap.full {
    position: absolute;
    width: 100%;
    height: calc(~"100vh - 60px");
    top: 0;
    left: 0;
    background: #2193c7;
	background: -webkit-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: -moz-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: -o-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: linear-gradient(to bottom right, #2193c7 0%, #146b98 100%);
    
    .positioner {
        position: absolute;
        width: auto;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    
    .desc {
        position: absolute;
        width: 100%;
        bottom: 20px;
        text-align: center;
        padding: 0 20px;
    }
}

.menu {
    position: relative;
    width: 200px;
    height: 60px;
    padding: 7px 0;

    background: #202428;

    p {
        padding: 0 10px;

        font-size: 15px;
        font-weight: 200;
        color: #dddddd;
    }

    .username {
        font-size: 20px;
        color: #ffffff;
    }

    .username.admin {
        color: #66CD00;
        font-weight: 400;
    }

    .icon {
        position: absolute;
        right: 10px;
        top: 20px;

        transform: rotateZ(90deg);

        &::before {
            content: ">";
            font-size: 15px;
            font-weight: bold;
        }
    }

    ul {
        display: none;
        position: relative;

        list-style: none;
        
        -webkit-box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);
        -moz-box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);
        box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);

        li {
            width: 100%;
            height: auto;
            background: #202428;

            a {
                display: inline-block;
                width: 100%;
                height: auto;
                padding: 10px;

                font-size: 17px;
                color: #ffffff;
                text-align: center;
            }

            &:hover {
                background: #000206;
            }
        }
    }

    &:hover {
        height: auto;
        background: #000206;
        padding: 7px 0 0;

        ul {
            padding-top: 7px;
            display: block;
            position: relative;
        }
    }
}

.header-wrap {
    margin-bottom: 60px;
    
    .header {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        height: 60px;
        background: #41464a;
        border-bottom: 1px solid #000206;
        z-index: 1020;

        -webkit-box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);
        -moz-box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);
        box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);
        
        .logo {
            display: inline-block;
            float: left;
            width: 200px;
            padding: 5px 34px;

            img {
                height: 90%;
            }
        }
        
        .menu {
            float: right;
            min-width: 250px;
            max-width: 350px;
        }

        .title {
            float: left;
            display: table;
            position: relative;
            width: auto;
            max-height: 60px;
            padding: 7px 20px 7px 10px;

            p {
                font-size: 15px;
                font-weight: 200;
                color: #dddddd;
            }

            .name {
                font-size: 20px;
                color: #ffffff;
                font-weight: 400;
            }
        }

        .header-btn-list {
            float: right;
            list-style: none;
            padding: 0;
            margin: 0 10px;

            li {
                display: inline-block;
                width: auto;
                margin: 8px 0px 7px 0px;
                color: #ffffff;
                font-size: 17px;

                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    font-weight: 400;
                    font-size: 21px;
                    color: #ebefef;
                    cursor: pointer;
                }

                &.new {
                    background: #313539;

                    &:hover {
                        background: #000206;
                    }
                }

                &.selected {
                    background: #146b98;
                }

                &:hover {
                    
                    a {
                        color: #dddddd;
                    }
                }
            }
        }
    }

    .subheader {
        position: relative;
        width: 100%;
        height: 40px;
        background: #202428;
        border-bottom: 1px solid #000206;

        z-index: 1010;

        -webkit-box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);
        -moz-box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);
        box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                width: auto;
                height: 40px;
                background: #313539;
                color: #ffffff;
                font-size: 17px;
                cursor: pointer;

                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    padding: 10px 20px 0;
                    color: #ffffff;
                }

                &.new {
                    background: #313539;

                    &:hover {
                        background: #000206;
                    }
                }

                &.selected {
                    background: #000206;
                }

                &:hover {
                    background: #000206;
                }
            }
        }
    }
}

.inner-content {
    position: relative;
    display: table;
    width: calc(~"100% - 200px");
    height: calc(~"100vh - 60px");
    top: 0px;
    left: 200px;
    
    &.full {
        width: 100%;
        left: 0;
    }
    
    &.smaller {
        width: calc(~"100% - 400px");
        left: 400px;
    }

	h2 {
		padding: 20px 20px 10px 20px;
	}
    
    .valid {
        width: auto;
		margin: 10px 10px 10px 0px;
        padding: 5px 10px;
		background: #4CBB17;
		border-radius: 2px;
	}

	.error {
        width: auto;
		margin: 10px 20px 10px 0px;
        padding: 5px 10px;
		background: #8b0000;
		border-radius: 2px;
	}
    
    .information {
        width: auto;
		margin: 10px 20px 10px 0px;
        padding: 5px 10px;
		background: #DD7500;
		border-radius: 2px;
	}

	table {
        padding: 0px 20px 10px;
	}

	table th {
	    padding: 5px 10px;
	    border: 2px solid #000000;
	}

	table td {
	   position: relative;
	    padding: 5px 10px;
	    border: 1px solid #000000;
	}

	table td p {
		float: left;
	}

	.table_title {
		display: inline-block;
		position: relative;
		width: 100%;
		height: auto;
	}

	.table_title h2 {
		float: left;
		padding: 10px 20px 10px 20px;
	}

	.difference {
		float: right;
        margin: 5px 5px 0;
		padding: 2px 5px;

		font-size: 15px;

		background: #8b0000;
		border-radius: 2px;
	}

	.options {
		position: relative;
        
        p {
            padding: 0;
        }

		select {
			padding: 5px 10px;
		}

		.update {
			display: inline-block;
			position: relative;

			button {
				float: left;
				position: relative;
                width: auto;
                height: auto;
                margin: 0px 5px;
                padding: 6px 9px 5px 10px;
				top: 7px;
                color: #ffffff;
                background: #313539;
                font-size: 16px;
                border: none;
                box-shadow: none;
                cursor: pointer;
			}

			.difference {
				position: relative;
				top: 5px;
                padding: 2px 5px;
			}
		}
	}
}

.sidebar {
    float: left;
    position: fixed;
    height: 100vh;
    width: 200px;
    top: 0px;
    left: 0px;
    padding-top: 70px;
    background: #202428;
    border-right: 1px solid #000206;
    overflow: hidden;
    z-index: 1020;
    
    -webkit-box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 5px 6px rgba(0,0,0,0.25);
    
    &.organisation-menu {
        height: calc(~"100vh - 60px");
        top: 60px;
        left: 200px;
        overflow-y: auto;
        z-index: 1000;
    }
    
    .logo {
        width: 100%;
        padding: 5px 0;
        text-align: center;
        
        img {
            height: 90%;
        }
    }
    
    h3 {
        padding: 10px 10px 5px 10px;
        font-size: 16px;
        font-weight: 200;
        color: #69bfed;
    }

	ul {
		height: auto;

		li {
            position: relative;
			list-style: none;
			width: 100%;
            cursor: pointer;
            
            .icon {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
            }

			a {
				display: inline-block;
				width: 100%;
				height: auto;
				padding: 10px 10px 10px 45px;

				font-size: 17px;
				color: #ffffff;
				text-align: left;
			}
            
            &.new {
                background: #313539;
                
                &:hover {
                    background: #000206;
                }
            }
            
            &.selected {
                border-left: 5px solid #257ca9;
                background: #146b98;
                
                .icon {
                    left: 15px;
                }
                
                a {
                    padding: 10px 10px 10px 40px;
                }
			}

			&:hover {    
                border-left: 5px solid #257ca9;
                
                .icon {
                    left: 15px;
                }
                
                a {
                    padding: 10px 10px 10px 40px;
                }
			}
		}
	}
}

.inner-content {
	form{
		padding: 10px 0;
		position: relative;
		z-index: 2;
        
        .form-section {
            box-sizing: border-box;
            display: inline-block;
            width: 100%;
            padding: 0 20px;
            
            h3 {
                padding-left: 0px;
                padding-bottom: 2px;
            }
            
            ul{
                display: flex;
                
                li {
                    display: flex;
                    flex: 1;
                    flex-flow: column;
                    list-style: none;
                    padding-right: 20px;
                    
                    &:last-child {
                        padding-right: 0px;
                    }

                    &.form-section__item--inline {
                        flex-flow: row;
                    }
                }
            }
            
            input {
                width: 100%;
                background-color: #146b98;
                border: 1px solid #202428;
                margin: 0;
                padding: 8px 10px;
                
                font-family: 'Source Sans Pro', sans-serif;
                text-align: left;
                
                &:hover,&:focus {
                    color: #ffffff;
                    background: #202428;
                }
                
                &.form__input--error {
                    background-color: #146b98;
                    border: 1px solid #ad2222;
                    border-left: 5px #ad2222 solid;
                    padding-left: 5px;
                }
            }

            input[type="checkbox"] {
                width: 20px;
                margin-top: 8px;
            }
            
            select {
                width: 100%;
                background-color: #146b98;
                border: 1px solid #202428;
                margin: 0;
                padding: 6px 10px;
                
                font-family: 'Source Sans Pro', sans-serif;
                
                &:hover,&:focus {
                    color: #ffffff;
                    background: #202428;
                }
                
                &.form__input--error {
                    background-color: #146b98;
                    border: 1px solid #ad2222;
                    border-left: 5px #ad2222 solid;
                    padding-left: 5px;
                }
                
                option {
                    background: #202428;
                    border: 1px solid #202428;
                }
            }
            
            button {
                color: #ffffff;
                background-color: #146b98;
                border: 1px solid #202428;
                margin: 20px 0 0;
                
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 700;
                font-size: 20px;
                
                &:hover,&:focus {
                    color: #ffffff;
                    background-color: #202428;
                }
                
                &.form__input--error {
                    background-color: #146b98;
                    border: 1px solid #ad2222;
                    border-left: 5px #ad2222 solid;
                    padding-left: 5px;
                }
            }
            
            .form__error {
                display: none;
                width: 100%;
                padding: 2px 5px;
                margin-top: -2px;
                background: #ad2222;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                font-family: 'Source Sans Pro', sans-serif;
                color: #ffffff;
                
                &.visible {
                    display: block;
                }
            }
        }
	}
}

.outlet-rules {
    width: 100%;
    padding: 10px 0;
    
    .outlet-rules__title {
        margin-bottom: 10px;
    }
    
    .outlet-rules__row {
        width: 100%;
        margin: 0 0 5px 5px;
    }
    
    .row__seperator {
        padding: 3px 5px;
    }
    
    .row__btn-wrap {
        margin: 5px;
    }
    
    .outlet-rules--created {
        .row__btn-wrap {
            margin: 5px 5px 5px 0;
        }
    }
    
    .row__btn,
    .outlet-rules__btn {
        margin: 1px 0 0;
        padding: 0px 6px;
        background-color: #41464a;
        border: 1px solid #202428;
        text-align: center;
        
        &:hover {
            background: #202428;
        }
    }
    
    .row__output-wrap {
        margin-top: 3px;
        
        .row__output-title {
            margin-right: 10px;
        }
        
        p {
            padding: 3px 0;
            font-size: 16px;
        }
    }
    
    .outlet-rules__btn {
        margin: 0 0 0 5px;
    }
    
    .outlet-rules--form {
        margin-top: 10px;
        
        ul {
            list-style: none;
            
            li {
                display: block;
                float: left;
                margin-right: 10px;
                
                .row__title {
                    
                }
            }
        }
        
        .fields {
            .fields__field{
                float: none;
                overflow: auto;
                margin-top: 5px;
            }
        }
        
        .row__input {
            width: 50px;
            margin: 0;
            padding: 5px 5px;
            background-color: #41464a;
            border: 1px solid #202428;
            color: #ffffff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 14px;
            text-align: left;
        }
        
        .row__select {
            width: auto;
            margin: 0;
            padding: 4px 5px;
            background-color: #41464a;
            border: 1px solid #202428;
            color: #ffffff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 14px;
            text-align: left;
        }
        
        .row__btn{
            margin: 4px 0 0;
        }
        
        .outlet-rules__btn {
            margin: 29px 0 0;
        }
    }
}

/*********************** Loading Indicator *************************/

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        border-top-color: rgba(255,255,255,0.5);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        border-top-color: rgba(255,255,255,0.5);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        border-top-color: rgba(255,255,255,0.5);
    }
}

.loadingPositioner {
	position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    background: rgba(0,0,0,0.8);
    z-index: 800;
}

.loadingDesc {
    position: absolute;
    top: calc(50% + 40px);
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: 'MyriadPro';
    font-weight: 300;
    font-size: 14px;
    color: #ffffff;
}

.loadingIndicator {
	position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: block;
    overflow: hidden;
    box-sizing: border-box;
}

.loadingIndicator div {
    height: 100%;
    box-sizing: border-box;
}

/* loader 4 */
.loadingIndicator div{
    border-radius: 50%;
    padding: 3px;
    border: 2px solid transparent;
    -webkit-animation: rotate linear 3.5s infinite;
    border-radius: 50%;
    padding: 1px;
    -webkit-animation: rotate2 4s infinite linear;
}

.loadingIndicator div:hover {
    -webkit-animation-play-state: paused;
}

.modal-blanket {
    position: fixed;
    width: calc(~"100% - 200px");
    height: 100%;
    left: 200px;
    top: 0;
    background: rgba(0,0,0,0.8);
    
    z-index: 1005;
    cursor: pointer;
}

.modal {
    position: fixed;
    width: calc(~"80% - 200px");
    height: 60%;
    top: calc(~"50% + 30px");
    left: calc(~"50% + 100px");
    transform: translate(-50%,-50%);
    margin: 10px 10px;
    cursor: pointer;
    background: #2193c7;
	background: -webkit-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: -moz-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: -o-linear-gradient(top left, #2193c7 0%, #146b98 100%);
	background: linear-gradient(to bottom right, #2193c7 0%, #146b98 100%);
    border: #2B616F;
    cursor: default;
    overflow: hidden;
    
    .modal-header {
        position: relative;
        padding: 10px 20px;
        background: #41464a;
        height: auto;
        overflow: auto;
        
        h1 {
            display: inline-block;
            float: left;
            height: 100%;
            overflow: auto;
            margin-top: 3px;
        }
    }
    
    .sidebar {
        padding-top: 0px;
    }
    
    .modal-center-wrap {
        position: relative;
        display: block;
        width: calc(~"100% - 200px");
        height: calc(~"100% - 60px");
        left: 200px;
        padding: 0;
        overflow-x: auto;
        
        &.full {
            width: 100%;
            left: 0px;
        }
        
        h2 {
            padding: 20px;
        }
        
        h3 {
            padding: 10px 20px;
        }
        
        .data-field {
            height: 100%;
            overflow: auto;
            padding: 20px 10px;
            margin: 0 20px;
            border-bottom: 2px solid #035a87;
        }

        .data-field__block {
            display: flex;
            padding-bottom: 5px;
            width: 100%;
            height: 100%;
        }

        .data-field__block h3 {
            padding-left: 0;
        }

        .data-field__block p {
            padding-right: 10px;
        }

        .data-field__note {
            font-size: 13px;
            color: #FF8D8D;
        }
        
        .first-child {
            border-top: 2px solid #035a87;
        }
        
        p {
            float: left;
            width: 250px;
        }
        
        p.error, p.information, p.log {
            float: none;
            width: auto;
            margin-left: 20px;
        }

        p.log {
            margin: 10px 20px;
            padding: 5px 10px;
            background: #44ab00;
            border-radius: 2px;
        }
        
        .list-wrap {
            float: left;
            
            ul,
            ol {
                margin-left: 15px;
            }
        }
        
        .CiviCRMClientSend {
            display: inline-block;
            width: 100%;
            height: auto;
            padding: 10px 20px;

            p {
                width: 100%;
            }
        }
        
        .modal-notification-blanket {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            
            background: rgba(0,0,0,0.2);
            z-index: 100;
            
            .modal-notification {
                position: absolute;
                width: auto;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                padding: 20px;
                background: rgba(0,0,0,0.6);

                p {
                    width: 100%;
                    text-align: center;
                    margin: 0 0 20px;
                }

                .buttons{
                    width: 100%;
                    text-align: center;

                    li {
                        display: inline-block;
                        padding: 10px 40px 10px 40px;
                        margin: 0 10px;
                        background: #2B616F;
                        cursor: pointer;

                        &.yes {
                            background: #44ab00;
                            
                            &:hover {
                                background: #228900;
                            }
                        }

                        &.no {
                            background: #ad2222;
                            
                            &:hover {
                                background: #8b0000;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .buttons button {
        margin: 10px 0 0 10px;
    }
    
    .CiviCRMClientSend {
        padding: 10px 0;
    }
    
    .sidebar {
        height: calc(~"100% - 63px");
        top: 63px;
        box-shadow: none;
        
        ul {

            li {

                div {
                    display: inline-block;
                    width: 100%;
                    height: auto;
                    padding: 10px;

                    font-size: 17px;
                    color: #ffffff;
                    text-align: center;
                    cursor: pointer;
                }
            }
            
            li.invalid {
                background: #8b0000;
            }
        }
    }
}

.content-header {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0 20px;
    background: #202428;
}

.content-header h2 {
    padding: 20px 0;
}

.content-subheader {
    position: relative;
    width: 100%;
    padding: 10px 20px;
    background: #41464a;
    
    h3 {
        font-size: 21px;
        padding: 0 0 10px;
    }
    
    h4 {
        color: #69bfed;
        font-size: 18px;
        padding: 5px 0 5px;
    }
    
    p {
        padding: 0 0 10px;
    }
    
    table {
        padding: 0;
        
        td {
            border: none;
            padding: 0;
            
            p {
                padding: 0 0 0 10px;
            }
        }
    }
}

.icon-field {
    position: absolute;
    right: 20px;
    
    .icon {
        margin-left: 10px;
        color: #ebefef;
        
        &.validated {
            color: #66CD00;
        }
    }
}

.buttons-new {
    width: auto;
    list-style: none;
    
    li {
        display: inline-block;
        margin-left: 5px;
        
        &:first-child {
            margin-left: 0;
        }
    }
    
    a, button {
        float: left;
        width: auto;
        height: auto;
        padding: 5px 10px; 
        background: #146b98;
        color: #ffffff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 15px;
        border: none; 
        box-shadow: none;
        cursor: pointer;
        
        &:hover {
            background: #035a87;
        }
        
        &.cancel {
            background: #ad2222;
        }
        
        &.validated {
            background: #44ab00;
        }
    }
}

.buttons {
    float: right;
    width: auto;
    
    button {
        float: left;
        width: auto;
        height: auto;
        padding: 6px 9px 5px 10px;
        background: #146b98;
        color: #ffffff;
        border: none; 
        box-shadow: none;
        cursor: pointer;
        
        &:hover {
            background: #035a87;
        }
        
        &.cancel {
            background: #ad2222;
        }
        
        &.validated {
            background: #44ab00;
        }
    }
    
    &.main {
        float: none;
        width: 100%;
        padding: 20px 20px 10px;
        
        button {
            float: none;
            margin: 0px 10px 0;
            
            &:hover {
            }
        }
    }
}

.data-list {
    display: table;
    width: 100%;
    height: auto;
    padding: 0;
    
    &.data-list--flex {
        
        .data {
            display: flex;
            
            .data-name2 {
                flex: 1;
            }
            
            .data-flex {
                flex: 1;
                padding: 0px 5px;
            }
            
            .difference {
                float: none;
                margin: 5px 5px 0 0;
            }
        }
    }
    
    .data-title-filter {
        width: 100%;
        height: auto;
        padding: 10px 20px;
        text-align: center;
        
        .internal-wrap {
            display: inline-block;
            width: auto;
            height: 100%;
            list-style: none;
            max-width: 100%;
            margin: 0 auto;
            text-align: center;
            
            li {
                display: inline-block;
                float: left;
                width: 40px;
                font-size: 20px;
                text-align: center;
                cursor: pointer;
                
                &:hover {
                    background: #035a87;
                }
                
                &.selected {
                    background: #035a87;
                }
            }
        }
    }
    
    .data-title {
        display: inline-block;
        width: 100%;
        height: auto;
        padding: 10px 20px;
        background: #202428;
        
        h2 {
            float: left;
            padding: 0;
            margin-right: 20px;
        }
        
        .btn {
            float: left;
            width: auto;
            height: 100%;
            margin-right: 10px;
            padding: 5px 10px;
            background: #313539;
            cursor: pointer;
            
            &:hover {
                background: #000206;
            }
            
            &.selected {
                background: #000206;
            }
        }
    }

    .data {
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        padding: 10px 20px;
        margin: 0;
        background: #41464a;
        
        transition: background .5s linear;
        
        &.view-btn {
            background: #257ca9;
            cursor: pointer;
            
            &:nth-child(odd) {
                background: #146b98;
                
                .counter {
                    color: #66ce22;
                    
                    &.fail {
                        color: #cf4444;
                    }
                }
            }
            
            &:hover {
                background: #035a87;
            }
            
            &.selected {
                background: #44ab00;
            
                &:hover {
                    background: #228900;
                }
                
                &:nth-child(odd) {
                    background: #228900;
                    
                    &:hover {
                        background: #44ab00;
                    }
                }
            }
            
            &.successful, &.isApproved, &.unlocked {
                padding-left: 10px;
                border-left: 10px #44ab00 solid;
                
                &:nth-child(odd) {
                    border-left: 10px #228900 solid;
                }

                &:hover {
                    
                }
            }
        
            &.failed, &.notApproved, &.locked {
                padding-left: 10px;
                border-left: 10px #8b0000 solid;
                
                &:nth-child(odd) {
                    border-left: 10px #ad2222 solid;
                }

                &:hover {
                    
                }
            }
        }
        
        .btn {
            float: right;
        }
        
        .error-wrap {
            
            ul {
                margin-left: 0px;
            }
        }
        
        div {
            float: left;
        }
        
        .data-link {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        
        .data-id {
            padding: 0 5px;
            width: 5%;
        }

        .data-id2 {
            padding: 0 5px;
            width: 10%;
        }

        .data-name {
            padding: 0 5px;
            width: 40%
        }

        .data-name1 {
            padding: 0 5px;
            width: 25%
        }

        .data-name2 {
            padding: 0 5px;
            width: 15%
        }

        .data-name3 {
            padding: 0 5px;
            width: 75%;
            word-break: break-all;
        }

        .data-output-brief {
            width: 55%;

            &.hidden {
                display: none;
            }

            .counter {
                font-weight: 700;
                float: right;
                color: #66ce22;
                transition: color .5s linear;
                    
                &.fail {
                    color: #cf4444;
                }
            }

            .caret {
                float: right;
                margin: 8px 0 0 10px;
                border-top: 10px dashed;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
            }
            
            .buttons {
                float: right;
            }
        }
        
        .data-output {
                
            div {
                margin-top: 10px;
            }

            .list-title {
                margin-top: 10px;
            }

            .success-wrap {
                width: 100%;
                padding: 5px;
                border-left: 5px #44ab00 solid;
            }

            .warning-wrap {
                width: 100%;
                padding: 5px;
                border-left: 5px #DD7500 solid;
            }

            .error-wrap {
                width: 100%;
                padding: 5px;
                border-left: 5px #8b0000 solid;
            }
        
            li {
                padding: 5px;
                background: none;
                list-style: none;
                margin-bottom: 0;

                &.success {
                    border-left: 5px #228900 solid;
                }

                &.information {
                    border-left: 5px #DD7500 solid;
                }

                &.error {
                    border-left: 5px #ad2222 solid;
                }
            }
        }
        
        &.expandable {
            
            &.expanded {
                .data-output {
                    height: 100%;
                }
            }
            
            .data-output {
                width: 100%;
                height: 0px;
                padding-left: 5%;
                overflow: hidden;
                
                transition: height .5s linear;
            }
        }
    }
    
    /*.error {
        margin: 0;
        padding: 0;
    }*/
}

select {
    padding: 10px 10px;
    background: #313539;
    border: none;
    color: #ffffff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    font-weight: 300;
    cursor: pointer;

    &:hover {
        background: #000206;
    }
}

.filter-wrap {
    display: flex;
    position: relative;
    width: 100%;
    padding: 20px;
    background: #202428;
    
    .dateTypeWrap {
        width: auto;
        position: relative;
        display: inline-block;
        width: auto;
    }

    .daterange {
        background: #313539;

        &:hover {
            background: #000206;
        }
    }
    
    .buttons {
        display: inline-block;
        width: auto;
        padding: 9px 0px;
        
        &.buttons--icons {
            display: flex;
            padding: 5px 0px;
            
            button {
                margin: 0;
                padding: 5px 7px;
                
                background: none;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 300;
                font-size: 20px;

                &.validated {
                    background: none;
                    color: #44ab00;
                    cursor: default;

                    &.selected {
                        background: none;
                    }

                    &:hover {
                        background: none;
                    }
                }

                &.selected {
                    background: none;
                }

                &:hover {
                    background: none;
                    
                    &.icon,
                    .icon {
                        color: #dddddd;
                    
                        &.icon--cancel {
                            color: #cf4444;
                        }
                    }
                }
                
                &.icon,
                .icon {
                    font-family: 'Glyphicons Halflings';
                    font-weight: 700;
                    font-size: 16px;
                }
            }
        }

        button {
            margin: 0px 5px;
            background: #313539;
            font-size: 16px;

            &.validated {
                background: #44ab00;

                &.selected {
                    background: #44ab00;
                }

                &:hover {
                    background: #44ab00;
                }
            }

            &.selected {
                background: #000206;
            }

            &:hover {
                background: #000206;
            }
        }
    }

    select {
        float: left;
        margin: 0 10px;
    }
}


@-webkit-keyframes icon--processing {
    0%   { color: #44AB00; }
    50% { color: #88ef22; }
    100% { color: #44AB00; }
}
@keyframes icon--processing {
    0%   { color: #44AB00; }
    50% { color: #88ef22; }
    100% { color: #44AB00; }
}

.icon {
    overflow: hidden;
    
    .icon__processing-cont {
        display: none;
    }
    
    &.icon--cancel {
        color: #ad2222;
    }
    
    &.icon--processing {
        -webkit-animation: icon--processing 5s infinite;
        animation:         icon--processing 5s infinite;
    }
}

.search-wrap {
    position: relative;
    width: 100%;
    padding: 20px;
    text-align: center;

    &.main {
        background: #202428;
        
        .dateTypeWrap {
            width: auto;
            position: relative;
            display: inline-block;
            width: auto;
        }
        
        .daterange {
            background: #313539;
            
            &:hover {
                background: #000206;
            }
        }
        
        button {
            background: #313539;
            font-size: 16px;
        
            &.validated {
                background: #44ab00;
                
                &.selected {
                    background: #44ab00;
                }
                
                &:hover {
                    background: #44ab00;
                }
            }
            
            &.selected {
				background: #000206;
			}

			&:hover {
				background: #000206;
			}
        }
        
        select {
            float: left;
            padding: 10px 10px;
            margin: 0 10px;
            background: #313539;
            border: none;
            color: #ffffff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 20px;
            font-weight: 300;
            cursor: pointer;
            
            &:hover {
				background: #000206;
			}
        }
    }
    
    &.small {
        padding: 0;
        z-index: 50;
        
        .buttons {
            position: relative;
            min-height: 57px;
            z-index: 50;
            button {
                font-size: 15px;
            }
        }
    }
    
    ::-webkit-input-placeholder { /* WebKit browsers */
        opacity: 0.8;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        opacity: 0.8;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        opacity: 0.8;
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
        opacity: 0.8;
    }
    
    .searchClients {
        width: 50%;
        padding: 10px;
        margin: 0 auto;
        background: #146b98;
        font-size: 20px;
        color: #ffffff;
        border: none;
        
        &:hover {
            background: #035a87;
        }
        
        &:focus {
            background: #035a87;
        }
    }
}

.info-icons {
    float: left;
    width: 400px;
    position: relative;
    height: 40px;
    padding: 0 20px;
    
    .error {
        cursor: pointer;
    }
    
    .ConsentedForFutureContact {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 50%;
        transform: translateX(-50%);
        background: #00ff00;
    }
    
    .issueCount {
        float: right;
        padding-left: 10px;
    }
    
    div {
        display: inline-block;
    }
}

.tabs-nav {
    display: flex;
    background: #313539;
    height: 40px;
    
    .tabs-nav__tab {
        text-align: center;
        font-size: 20px;
        padding: 5px 20px;
        
        cursor: pointer;
        
        &:hover,
        &.selected {
            background: #202428;
        }
    }
}

.graphs {
    display: flex;
    flex-wrap: wrap;
    
    .graph-wrap {
        padding: 10px 20px;
        margin: 10px 0px 10px 20px;
        
        background: #202428;
        
        -webkit-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.3);
        -moz-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.3);
        box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.3);
        
        &.graph-wrap--client-gender {

        }
        
        h3 {
            font-size: 28px;
        }
        
        .basic-table {
            padding: 0;
            
            td {
                border: none;
            }
        }
        
        &.download {
            cursor: pointer;
            
            .icon {
                font-size: 21px;
            }
        }
    }
}

.pie-chart {
    position: relative;
    
    svg {
        float: left;
    }
    
    .legend {
        float: left;
        padding: 0px 20px 10px;
        
        tr {
            border-bottom: 2px solid #035a87;
        }
        
        tr:nth-child(1) {
            border-top: 2px solid #035a87;
        }
        
        td {
            border: none;
        }
    }
}

.no-results {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 60px;
    transform: translate(-50%,-50%);
    text-align: center;
    
    .icon {
        font-size: 90px;
    }
}

.civiExchangeLogo {
    float: left;
    width: 120px;
    margin: 5px 40px 5px 40px;
}

.civiAssistLogo {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding: 10px 0;
    text-align: center;
    background: #202428;
    
    img {
        width: 185px;
    }
}

.processing-info {
    
    &.hidden {
        display: none;
    }
}

.processing-results {
    
    &.hidden {
        display: none;
    }
}

.SendProcessLog {
    height: 400px;
    padding: 10px 20px;
    background: #146b98;
    overflow-x: auto;
    
    &.hidden {
        display: none;
    }
}

.data-list.sending {
    margin: 0;
}

.successfullContainer {
    float: left;
    width: 50%;
    height: 400px;
    padding: 10px 20px;
    background: #66CD00;
    overflow-x: auto;
    
    &.hidden {
        display: none;
    }
}

.failContainer {
    float: left;
    width: 50%;
    height: 400px;
    padding: 10px 20px;
    background: #8b0000;
    overflow-x: auto;
    
    &.hidden {
        display: none;
    }
}

.progressBarWrap {
    padding: 0;
    
    .progressBar {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 113px;
        text-align: center;

        .progressIndicator {
            position: absolute;
            width: 0%;
            height: 100%;
            background: #035a87;
            z-index: 49;
        }
        
        .percentage {
            position: relative;
            z-index: 50;
        }
        
        h2 {
            position: relative;
            padding-top: 0;
            z-index: 51;
        }
    }
}

.transferLog {
    position: relative;
    width: 100%;
    padding: 10px 20px;
    list-style: none;
    z-index: 50;
    background: rgba(0,0,0,0.6);
    
    .progressTotals {
        display: inline-block;
        width: 33%;
        
        &.success {
            text-align: left;
            
            h3, p {
                color: #66CD00;
            }
        }

        &.total {
            text-align: center;
            
            li {
                width: 200px;
            }
        }

        &.failed {
            text-align: right;
            
            h3, p {
                color: #d34d4d;
            }
        }

        li {
            display: inline-block;
            width: 100px;
            text-align: center;

            p {
                font-size: 40px;

                &.sml {
                   font-size: 16px; 
                }
            }
        }
    }
}

.sendProcessDetails {
    width: 100%;
    
    p {
        width: auto;
        padding: 10px 20px 0;
    }
}

.setup-required,
.setup{
    
    .center {
        text-align: center;
    
        h1{
            font-size: 50px;
            font-weight: 200;
            padding: 20px 20px 10px 20px;
        }
        
        h2 {
            font-size: 26px;
            padding-top: 0px;
        }

        p {
            font-size: 18px;
            padding-top: 0px;
            padding-bottom: 20px;
        }
        
        b {
            font-weight: 700;
        }
        
        .form {
            background: #035a87;
            
            h4 {
                font-size: 22px;
                padding: 0px 20px 10px;
            }
            
            ul {
                list-style: none;
                
                li {
                    display: inline-block;
                }
            }
            
            .form-section {
                width: 100%;
                margin: 0 auto;
            }
        
            input {
                display: inline-block;
                margin: 0 10px 10px;
            }
            
            button {
                margin: 0 10px 10px;
            }
            
            .loadingWrap{
                height: 100%;
                background: rgba(0,0,0,0.9);
                
                h4 {
                    padding: 10px 40px 10px;
                    font-size: 20px;
                }
            }
        }
        
        .feedback {
            
            .error {
                display: table;
                padding: 5px 10px;
                margin: 10px auto 0;
            }
            
            .success {
                display: table;
                padding: 5px 10px;
                margin: 10px auto 0;
                background: #66CD00;
            }
            
            /*.loadingWrap{
                
                .bubble {
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                }
                
                h4 {
                    
                }
            }*/
        }
    }
}

.civi-notification {
    position: relative;
    width: 80%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    
    h2 {
        font-size: 30px;
        text-align: center;
    }
    
    p {
        font-size: 18px;
    }
        
    b {
        font-weight: 700;
        color: #8b0000;
    }
    
    ul {
        list-style: none;
    }
}

.btn__back {
    position: relative;
    float: left;
    width: 60px;
    height: 60px;
    font-size: 22px;
    border: 0px;
    color: #ffffff;
    
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    
    &:hover {
        background: #000206;
        cursor: pointer;
    }
}
//
// Glyphicons for Bootstrap
//
// Since icons are fonts, they can be placed anywhere text is placed and are
// thus automatically sized to match the surrounding child. To use, create an
// inline element with the appropriate classes, like so:
//
// <a href="#"><span class="glyphicon glyphicon-star"></span> Star</a>

// Catchall baseclass
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.buttons button.glyphicon {
    background: none;
}

.buttons button.glyphicon--red {
    color: #cf4444;
}

.buttons button.glyphicon--yellow {
    color: #DD7500;
}

// Individual icons
.glyphicon-asterisk               { &:before { content: "\002a"; } }
.glyphicon-plus                   { &:before { content: "\002b"; } }
.glyphicon-euro,
.glyphicon-eur                    { &:before { content: "\20ac"; } }
.glyphicon-minus                  { &:before { content: "\2212"; } }
.glyphicon-cloud                  { &:before { content: "\2601"; } }
.glyphicon-envelope               { &:before { content: "\2709"; } }
.glyphicon-pencil                 { &:before { content: "\270f"; } }
.glyphicon-glass                  { &:before { content: "\e001"; } }
.glyphicon-music                  { &:before { content: "\e002"; } }
.glyphicon-search                 { &:before { content: "\e003"; } }
.glyphicon-heart                  { &:before { content: "\e005"; } }
.glyphicon-star                   { &:before { content: "\e006"; } }
.glyphicon-star-empty             { &:before { content: "\e007"; } }
.glyphicon-user                   { &:before { content: "\e008"; } }
.glyphicon-film                   { &:before { content: "\e009"; } }
.glyphicon-th-large               { &:before { content: "\e010"; } }
.glyphicon-th                     { &:before { content: "\e011"; } }
.glyphicon-th-list                { &:before { content: "\e012"; } }
.glyphicon-ok                     { &:before { content: "\e013"; } }
.glyphicon-remove                 { &:before { content: "\e014"; } }
.glyphicon-zoom-in                { &:before { content: "\e015"; } }
.glyphicon-zoom-out               { &:before { content: "\e016"; } }
.glyphicon-off                    { &:before { content: "\e017"; } }
.glyphicon-signal                 { &:before { content: "\e018"; } }
.glyphicon-cog                    { &:before { content: "\e019"; } }
.glyphicon-trash                  { &:before { content: "\e020"; } }
.glyphicon-home                   { &:before { content: "\e021"; } }
.glyphicon-file                   { &:before { content: "\e022"; } }
.glyphicon-time                   { &:before { content: "\e023"; } }
.glyphicon-road                   { &:before { content: "\e024"; } }
.glyphicon-download-alt           { &:before { content: "\e025"; } }
.glyphicon-download               { &:before { content: "\e026"; } }
.glyphicon-upload                 { &:before { content: "\e027"; } }
.glyphicon-inbox                  { &:before { content: "\e028"; } }
.glyphicon-play-circle            { &:before { content: "\e029"; } }
.glyphicon-repeat                 { &:before { content: "\e030"; } }
.glyphicon-refresh                { &:before { content: "\e031"; } }
.glyphicon-list-alt               { &:before { content: "\e032"; } }
.glyphicon-lock                   { &:before { content: "\e033"; } }
.glyphicon-flag                   { &:before { content: "\e034"; } }
.glyphicon-headphones             { &:before { content: "\e035"; } }
.glyphicon-volume-off             { &:before { content: "\e036"; } }
.glyphicon-volume-down            { &:before { content: "\e037"; } }
.glyphicon-volume-up              { &:before { content: "\e038"; } }
.glyphicon-qrcode                 { &:before { content: "\e039"; } }
.glyphicon-barcode                { &:before { content: "\e040"; } }
.glyphicon-tag                    { &:before { content: "\e041"; } }
.glyphicon-tags                   { &:before { content: "\e042"; } }
.glyphicon-book                   { &:before { content: "\e043"; } }
.glyphicon-bookmark               { &:before { content: "\e044"; } }
.glyphicon-print                  { &:before { content: "\e045"; } }
.glyphicon-camera                 { &:before { content: "\e046"; } }
.glyphicon-font                   { &:before { content: "\e047"; } }
.glyphicon-bold                   { &:before { content: "\e048"; } }
.glyphicon-italic                 { &:before { content: "\e049"; } }
.glyphicon-text-height            { &:before { content: "\e050"; } }
.glyphicon-text-width             { &:before { content: "\e051"; } }
.glyphicon-align-left             { &:before { content: "\e052"; } }
.glyphicon-align-center           { &:before { content: "\e053"; } }
.glyphicon-align-right            { &:before { content: "\e054"; } }
.glyphicon-align-justify          { &:before { content: "\e055"; } }
.glyphicon-list                   { &:before { content: "\e056"; } }
.glyphicon-indent-left            { &:before { content: "\e057"; } }
.glyphicon-indent-right           { &:before { content: "\e058"; } }
.glyphicon-facetime-video         { &:before { content: "\e059"; } }
.glyphicon-picture                { &:before { content: "\e060"; } }
.glyphicon-map-marker             { &:before { content: "\e062"; } }
.glyphicon-adjust                 { &:before { content: "\e063"; } }
.glyphicon-tint                   { &:before { content: "\e064"; } }
.glyphicon-edit                   { &:before { content: "\e065"; } }
.glyphicon-share                  { &:before { content: "\e066"; } }
.glyphicon-check                  { &:before { content: "\e067"; } }
.glyphicon-move                   { &:before { content: "\e068"; } }
.glyphicon-step-backward          { &:before { content: "\e069"; } }
.glyphicon-fast-backward          { &:before { content: "\e070"; } }
.glyphicon-backward               { &:before { content: "\e071"; } }
.glyphicon-play                   { &:before { content: "\e072"; } }
.glyphicon-pause                  { &:before { content: "\e073"; } }
.glyphicon-stop                   { &:before { content: "\e074"; } }
.glyphicon-forward                { &:before { content: "\e075"; } }
.glyphicon-fast-forward           { &:before { content: "\e076"; } }
.glyphicon-step-forward           { &:before { content: "\e077"; } }
.glyphicon-eject                  { &:before { content: "\e078"; } }
.glyphicon-chevron-left           { &:before { content: "\e079"; } }
.glyphicon-chevron-right          { &:before { content: "\e080"; } }
.glyphicon-plus-sign              { &:before { content: "\e081"; } }
.glyphicon-minus-sign             { &:before { content: "\e082"; } }
.glyphicon-remove-sign            { &:before { content: "\e083"; } }
.glyphicon-ok-sign                { &:before { content: "\e084"; } }
.glyphicon-question-sign          { &:before { content: "\e085"; } }
.glyphicon-info-sign              { &:before { content: "\e086"; } }
.glyphicon-screenshot             { &:before { content: "\e087"; } }
.glyphicon-remove-circle          { &:before { content: "\e088"; } }
.glyphicon-ok-circle              { &:before { content: "\e089"; } }
.glyphicon-ban-circle             { &:before { content: "\e090"; } }
.glyphicon-arrow-left             { &:before { content: "\e091"; } }
.glyphicon-arrow-right            { &:before { content: "\e092"; } }
.glyphicon-arrow-up               { &:before { content: "\e093"; } }
.glyphicon-arrow-down             { &:before { content: "\e094"; } }
.glyphicon-share-alt              { &:before { content: "\e095"; } }
.glyphicon-resize-full            { &:before { content: "\e096"; } }
.glyphicon-resize-small           { &:before { content: "\e097"; } }
.glyphicon-exclamation-sign       { &:before { content: "\e101"; } }
.glyphicon-gift                   { &:before { content: "\e102"; } }
.glyphicon-leaf                   { &:before { content: "\e103"; } }
.glyphicon-fire                   { &:before { content: "\e104"; } }
.glyphicon-eye-open               { &:before { content: "\e105"; } }
.glyphicon-eye-close              { &:before { content: "\e106"; } }
.glyphicon-warning-sign           { &:before { content: "\e107"; } }
.glyphicon-plane                  { &:before { content: "\e108"; } }
.glyphicon-calendar               { &:before { content: "\e109"; } }
.glyphicon-random                 { &:before { content: "\e110"; } }
.glyphicon-comment                { &:before { content: "\e111"; } }
.glyphicon-magnet                 { &:before { content: "\e112"; } }
.glyphicon-chevron-up             { &:before { content: "\e113"; } }
.glyphicon-chevron-down           { &:before { content: "\e114"; } }
.glyphicon-retweet                { &:before { content: "\e115"; } }
.glyphicon-shopping-cart          { &:before { content: "\e116"; } }
.glyphicon-folder-close           { &:before { content: "\e117"; } }
.glyphicon-folder-open            { &:before { content: "\e118"; } }
.glyphicon-resize-vertical        { &:before { content: "\e119"; } }
.glyphicon-resize-horizontal      { &:before { content: "\e120"; } }
.glyphicon-hdd                    { &:before { content: "\e121"; } }
.glyphicon-bullhorn               { &:before { content: "\e122"; } }
.glyphicon-bell                   { &:before { content: "\e123"; } }
.glyphicon-certificate            { &:before { content: "\e124"; } }
.glyphicon-thumbs-up              { &:before { content: "\e125"; } }
.glyphicon-thumbs-down            { &:before { content: "\e126"; } }
.glyphicon-hand-right             { &:before { content: "\e127"; } }
.glyphicon-hand-left              { &:before { content: "\e128"; } }
.glyphicon-hand-up                { &:before { content: "\e129"; } }
.glyphicon-hand-down              { &:before { content: "\e130"; } }
.glyphicon-circle-arrow-right     { &:before { content: "\e131"; } }
.glyphicon-circle-arrow-left      { &:before { content: "\e132"; } }
.glyphicon-circle-arrow-up        { &:before { content: "\e133"; } }
.glyphicon-circle-arrow-down      { &:before { content: "\e134"; } }
.glyphicon-globe                  { &:before { content: "\e135"; } }
.glyphicon-wrench                 { &:before { content: "\e136"; } }
.glyphicon-tasks                  { &:before { content: "\e137"; } }
.glyphicon-filter                 { &:before { content: "\e138"; } }
.glyphicon-briefcase              { &:before { content: "\e139"; } }
.glyphicon-fullscreen             { &:before { content: "\e140"; } }
.glyphicon-dashboard              { &:before { content: "\e141"; } }
.glyphicon-paperclip              { &:before { content: "\e142"; } }
.glyphicon-heart-empty            { &:before { content: "\e143"; } }
.glyphicon-link                   { &:before { content: "\e144"; } }
.glyphicon-phone                  { &:before { content: "\e145"; } }
.glyphicon-pushpin                { &:before { content: "\e146"; } }
.glyphicon-usd                    { &:before { content: "\e148"; } }
.glyphicon-gbp                    { &:before { content: "\e149"; } }
.glyphicon-sort                   { &:before { content: "\e150"; } }
.glyphicon-sort-by-alphabet       { &:before { content: "\e151"; } }
.glyphicon-sort-by-alphabet-alt   { &:before { content: "\e152"; } }
.glyphicon-sort-by-order          { &:before { content: "\e153"; } }
.glyphicon-sort-by-order-alt      { &:before { content: "\e154"; } }
.glyphicon-sort-by-attributes     { &:before { content: "\e155"; } }
.glyphicon-sort-by-attributes-alt { &:before { content: "\e156"; } }
.glyphicon-unchecked              { &:before { content: "\e157"; } }
.glyphicon-expand                 { &:before { content: "\e158"; } }
.glyphicon-collapse-down          { &:before { content: "\e159"; } }
.glyphicon-collapse-up            { &:before { content: "\e160"; } }
.glyphicon-log-in                 { &:before { content: "\e161"; } }
.glyphicon-flash                  { &:before { content: "\e162"; } }
.glyphicon-log-out                { &:before { content: "\e163"; } }
.glyphicon-new-window             { &:before { content: "\e164"; } }
.glyphicon-record                 { &:before { content: "\e165"; } }
.glyphicon-save                   { &:before { content: "\e166"; } }
.glyphicon-open                   { &:before { content: "\e167"; } }
.glyphicon-saved                  { &:before { content: "\e168"; } }
.glyphicon-import                 { &:before { content: "\e169"; } }
.glyphicon-export                 { &:before { content: "\e170"; } }
.glyphicon-send                   { &:before { content: "\e171"; } }
.glyphicon-floppy-disk            { &:before { content: "\e172"; } }
.glyphicon-floppy-saved           { &:before { content: "\e173"; } }
.glyphicon-floppy-remove          { &:before { content: "\e174"; } }
.glyphicon-floppy-save            { &:before { content: "\e175"; } }
.glyphicon-floppy-open            { &:before { content: "\e176"; } }
.glyphicon-credit-card            { &:before { content: "\e177"; } }
.glyphicon-transfer               { &:before { content: "\e178"; } }
.glyphicon-cutlery                { &:before { content: "\e179"; } }
.glyphicon-header                 { &:before { content: "\e180"; } }
.glyphicon-compressed             { &:before { content: "\e181"; } }
.glyphicon-earphone               { &:before { content: "\e182"; } }
.glyphicon-phone-alt              { &:before { content: "\e183"; } }
.glyphicon-tower                  { &:before { content: "\e184"; } }
.glyphicon-stats                  { &:before { content: "\e185"; } }
.glyphicon-sd-video               { &:before { content: "\e186"; } }
.glyphicon-hd-video               { &:before { content: "\e187"; } }
.glyphicon-subtitles              { &:before { content: "\e188"; } }
.glyphicon-sound-stereo           { &:before { content: "\e189"; } }
.glyphicon-sound-dolby            { &:before { content: "\e190"; } }
.glyphicon-sound-5-1              { &:before { content: "\e191"; } }
.glyphicon-sound-6-1              { &:before { content: "\e192"; } }
.glyphicon-sound-7-1              { &:before { content: "\e193"; } }
.glyphicon-copyright-mark         { &:before { content: "\e194"; } }
.glyphicon-registration-mark      { &:before { content: "\e195"; } }
.glyphicon-cloud-download         { &:before { content: "\e197"; } }
.glyphicon-cloud-upload           { &:before { content: "\e198"; } }
.glyphicon-tree-conifer           { &:before { content: "\e199"; } }
.glyphicon-tree-deciduous         { &:before { content: "\e200"; } }
.glyphicon-cd                     { &:before { content: "\e201"; } }
.glyphicon-save-file              { &:before { content: "\e202"; } }
.glyphicon-open-file              { &:before { content: "\e203"; } }
.glyphicon-level-up               { &:before { content: "\e204"; } }
.glyphicon-copy                   { &:before { content: "\e205"; } }
.glyphicon-paste                  { &:before { content: "\e206"; } }
// The following 2 Glyphicons are omitted for the time being because
// they currently use Unicode codepoints that are outside the
// Basic Multilingual Plane (BMP). Older buggy versions of WebKit can't handle
// non-BMP codepoints in CSS string escapes, and thus can't display these two icons.
// Notably, the bug affects some older versions of the Android Browser.
// More info: https://github.com/twbs/bootstrap/issues/10106
// .glyphicon-door                   { &:before { content: "\1f6aa"; } }
// .glyphicon-key                    { &:before { content: "\1f511"; } }
.glyphicon-alert                  { &:before { content: "\e209"; } }
.glyphicon-equalizer              { &:before { content: "\e210"; } }
.glyphicon-king                   { &:before { content: "\e211"; } }
.glyphicon-queen                  { &:before { content: "\e212"; } }
.glyphicon-pawn                   { &:before { content: "\e213"; } }
.glyphicon-bishop                 { &:before { content: "\e214"; } }
.glyphicon-knight                 { &:before { content: "\e215"; } }
.glyphicon-baby-formula           { &:before { content: "\e216"; } }
.glyphicon-tent                   { &:before { content: "\26fa"; } }
.glyphicon-blackboard             { &:before { content: "\e218"; } }
.glyphicon-bed                    { &:before { content: "\e219"; } }
.glyphicon-apple                  { &:before { content: "\f8ff"; } }
.glyphicon-erase                  { &:before { content: "\e221"; } }
.glyphicon-hourglass              { &:before { content: "\231b"; } }
.glyphicon-lamp                   { &:before { content: "\e223"; } }
.glyphicon-duplicate              { &:before { content: "\e224"; } }
.glyphicon-piggy-bank             { &:before { content: "\e225"; } }
.glyphicon-scissors               { &:before { content: "\e226"; } }
.glyphicon-bitcoin                { &:before { content: "\e227"; } }
.glyphicon-btc                    { &:before { content: "\e227"; } }
.glyphicon-xbt                    { &:before { content: "\e227"; } }
.glyphicon-yen                    { &:before { content: "\00a5"; } }
.glyphicon-jpy                    { &:before { content: "\00a5"; } }
.glyphicon-ruble                  { &:before { content: "\20bd"; } }
.glyphicon-rub                    { &:before { content: "\20bd"; } }
.glyphicon-scale                  { &:before { content: "\e230"; } }
.glyphicon-ice-lolly              { &:before { content: "\e231"; } }
.glyphicon-ice-lolly-tasted       { &:before { content: "\e232"; } }
.glyphicon-education              { &:before { content: "\e233"; } }
.glyphicon-option-horizontal      { &:before { content: "\e234"; } }
.glyphicon-option-vertical        { &:before { content: "\e235"; } }
.glyphicon-menu-hamburger         { &:before { content: "\e236"; } }
.glyphicon-modal-window           { &:before { content: "\e237"; } }
.glyphicon-oil                    { &:before { content: "\e238"; } }
.glyphicon-grain                  { &:before { content: "\e239"; } }
.glyphicon-sunglasses             { &:before { content: "\e240"; } }
.glyphicon-text-size              { &:before { content: "\e241"; } }
.glyphicon-text-color             { &:before { content: "\e242"; } }
.glyphicon-text-background        { &:before { content: "\e243"; } }
.glyphicon-object-align-top       { &:before { content: "\e244"; } }
.glyphicon-object-align-bottom    { &:before { content: "\e245"; } }
.glyphicon-object-align-horizontal{ &:before { content: "\e246"; } }
.glyphicon-object-align-left      { &:before { content: "\e247"; } }
.glyphicon-object-align-vertical  { &:before { content: "\e248"; } }
.glyphicon-object-align-right     { &:before { content: "\e249"; } }
.glyphicon-triangle-right         { &:before { content: "\e250"; } }
.glyphicon-triangle-left          { &:before { content: "\e251"; } }
.glyphicon-triangle-bottom        { &:before { content: "\e252"; } }
.glyphicon-triangle-top           { &:before { content: "\e253"; } }
.glyphicon-console                { &:before { content: "\e254"; } }
.glyphicon-superscript            { &:before { content: "\e255"; } }
.glyphicon-subscript              { &:before { content: "\e256"; } }
.glyphicon-menu-left              { &:before { content: "\e257"; } }
.glyphicon-menu-right             { &:before { content: "\e258"; } }
.glyphicon-menu-down              { &:before { content: "\e259"; } }
.glyphicon-menu-up                { &:before { content: "\e260"; } }
.glyphicon-im-users               { &:before { content: "\e972"; } }
.glyphicon-im-cogs                { &:before { content: "\e995"; } }
.glyphicon-im-pie-chart           { &:before { content: "\e99a"; } }
.glyphicon-im-stats-dots          { &:before { content: "\e99b"; } }
.glyphicon-im-stats-bars          { &:before { content: "\e99c"; } }
.glyphicon-im-cloud-upload        { &:before { content: "\e9c3"; } }
.glyphicon-im-cloud-check         { &:before { content: "\e9c4"; } }

.daterange {
    float: left;
    padding: 10px;
    margin: 0 10px;
    background: #146b98;
    font-size: 20px;
    text-align: left;
    color: #ffffff;
    border: none;
    cursor: pointer;
    
    i {
        margin: 0 5px 0;
    }
    
    .caret {
        margin: 0 0 0 10px;
    }
}

.daterangepicker.dropdown-menu {
    display: none;
}

.daterangepicker .calendar th, .daterangepicker .calendar td {
    color: #000000;
}

.daterangepicker .calendar td.off {
    color: #cccccc;
}

.daterangepicker .input-mini {
    text-align: center;
    padding: 0 10px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.notification {
    text-align: center;
}

.services {
    display: flex;
    
    .service {
        position: relative;
        width: 50%;
        height: auto;
        flex-wrap: wrap;
        margin: 10px;
        background: #202428;
        padding-bottom: 15px;
        border: 2px solid #202428;
        
        &:hover {
            background: #000206;
        }
        
        p {
            padding: 0 20px 5px;
        }

        .url {
            color: #43b5e9;
        }
        
        a {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        
        .error-wrap {
            
        }
        
        .error {
            margin-bottom: 0px;
        }
        
        .icon {
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }
}

/************************************************/
